<template>
  <div class="story">
    <div class="title">
      <img :src="$t('story.topTitle')" alt="">
    </div>
    <div class="mid">
      <a-space direction="vertical" :size="56">
        <div class="mid1">
          <img :src="$t('story.mid1')" alt="">
        </div>
        <div class="mid2">
          <img :src="$t('story.mid2')" alt="">
        </div>
        <div class="mid3">
          <img :src="$t('story.mid3')" alt="">
        </div>
        <!-- <div class="mid4">
          <img :src="$t('story.mid4')" alt="">
        </div> -->
      </a-space>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  window.scrollTo(0, 0)
})
</script>

<style lang="less" scoped>
// .story {
//   background-image: url("https://assets-img.myazstore.com/medwory/bg.png");
//   background-size: 100% auto;
// }
.title {
  // padding-left: 12.5%;
  padding-top: 101px;
  padding-bottom: 49px;

  img {
    width: 327px;
  }
}
.mid {
  padding-bottom: 100px;
  // .mid1 {
  //   padding: 0 12.5%;
  // }

  // .mid2, .mid4 {
  //   padding: 0 12.5% ;
  // }

  // .mid3 {
  //   padding: 0 12.5%;
  // }

  img {
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 1440px) {
  .story {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
